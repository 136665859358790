import React from "react";
import unknownIcon from "@/assets/img/unknown-icon.png";
import websiteIcon from "@/assets/svg/export.svg";
import { truncateText } from "@/base/helpers/truncateText";
import LinkComponent from "../Link";
import StatusBadge from "../StatusBadge";
import * as styles from "./styles.module.css";

type Props = { project: PortfolioProjectType; slug: string };

export default function ProjectTile({ project, slug }: Props) {
  const {
    project_socials,
    project_logo,
    project_status,
    project_name,
    project_description,
  } = project;

  const truncatedDescription = truncateText(project_description, 200);
  const projectWeb = project_socials?.filter(
    (social) => social.type === "website"
  )[0];

  return (
    <div className={styles.projectWrapper}>
      {project_status && <StatusBadge status={project_status} />}
      <div className={styles.centerContent}>
        <img
          className={styles.logo}
          src={project_logo?.filename || unknownIcon}
          alt={project_name}
        />
        <p className={styles.description}>{truncatedDescription}</p>
      </div>
      <div className="mt-auto flex w-full justify-between items-center opacity-40 lg:pt-2 xl:pt-4 lg:border-t">
        <p className="lg:text-xxs xl:text-sm tracking-normal font-fieldwork-hum leading-none">
          {project_name}
        </p>
        {projectWeb && (
          <LinkComponent
            url={`/projects/${slug}`}
            type="internal"
            className="flex-shrink-0 flex-grow-0"
          >
            <img src={websiteIcon} alt={`Visit ${project_name} website`} />
          </LinkComponent>
        )}
      </div>
    </div>
  );
}
