import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import clsx from "clsx";
import ProjectTile from "@/components/elements/ProjectTile";
import { ReactComponent as ArrowLeftIcon } from "@/assets/svg/chevron-left.svg";
import * as styles from "./styles.module.css";

export default function InvestmentsSection({
  title = "Investments",
  companies = [],
}: Partial<InvestmentsSectionType>) {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  const sortedCompanies = companies.sort((companyA, companyB) => {
    const weightA = Number(companyA.content.project_weight);
    const weightB = Number(companyB.content.project_weight);

    if (weightA < weightB) return -1;
    if (weightA > weightB) return 1;
    return 0;
  });

  return (
    <section
      className={clsx(
        "mx-auto max-w-x-big px-4 lg:my-32 my-16",
        styles.container
      )}
    >
      <div className={styles.heading}>
        <h2 className="mb-0">{title}</h2>
        <div className="flex gap-2">
          <button
            title="Previous"
            onClick={() => sliderRef.current?.slickPrev()}
            className="w-11 h-11 rounded-full border-border border border-solid flex justify-center items-center disabled:opacity-50 disabled:cursor-default cursor-pointer"
            disabled={activeIndex === 0}
          >
            <ArrowLeftIcon />
          </button>
          <button
            title="Next"
            onClick={() => sliderRef.current?.slickNext()}
            className="w-11 h-11 rounded-full border-border border border-solid flex justify-center items-center disabled:opacity-50 disabled:cursor-default cursor-pointer"
            disabled={activeIndex === companies.length - 1}
          >
            <ArrowLeftIcon className="rotate-180" />
          </button>
        </div>
      </div>

      <div className={styles.slideWrapper}>
        <Slider
          {...SLIDER_SETTINGS}
          ref={sliderRef}
          beforeChange={(_currentIndex, nextIndex) => setActiveIndex(nextIndex)}
        >
          {sortedCompanies.map((company) => (
            <ProjectTile
              key={company.uuid}
              project={company.content}
              slug={company.slug}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
}

const SLIDER_SETTINGS: Settings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  pauseOnHover: false,
  variableWidth: true,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        variableWidth: false,
      },
    },
  ],
};
