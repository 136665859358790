import React from "react";
import clsx from "clsx";
import * as styles from "./styles.module.css";

type Props = { status: "recent" | "active" | string };

export default function StatusBadge({ status }: Props) {
  const isRecent = status === "recent" && "Recent";
  const isActive = status === "active" && "Active";
  const isExited = status?.includes("exited") && "Exited";

  return (
    <div
      title="Project status"
      className={clsx(
        styles.container,
        "absolute right-0 top-0 text-white z-10",
        {
          "bg-exitedBadge": isExited,
          "bg-recentBadge": isRecent || isActive,
        }
      )}
    >
      {isExited || isRecent || isActive}
    </div>
  );
}
