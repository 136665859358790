import React from "react";

import clsx from "clsx";
import NewsImage from "@/assets/img/news.png";
import { ImageComponent } from "@/components/elements/Image";
import LinkComponent from "@/components/elements/Link";
import formatDate from "@/base/helpers/formatDate";
import { ReactComponent as WhiteLogo } from "@/assets/svg/logo-vertex-white.svg";
import * as styles from "./styles.module.css";

interface Props {
  videoData: VideoType;
  storyblokPreview?: boolean;
}

export const VideoCard = ({ videoData, storyblokPreview }: Props) => {
  const linkUrl = videoData.external_link;
  const linkType = "external";

  const { video_title, video_hero, video_date, video_author } = videoData;
  const isAuthor = !!video_author && typeof video_author === "object";

  const bgColor = isAuthor
    ? video_author.content?.project_color?.color
    : "#7dcaef";
  const logo = isAuthor ? video_author.content?.project_logoWhite : null;
  const projectName = video_author?.name;
  function handleLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    if (linkUrl) {
      window.open(linkUrl, "_blank");
    }
  }

  return (
    <article
      className={clsx(
        "bg-white rounded-lg shadow-lg mb-10 2xl:mb-16",
        styles.wrapper,
        storyblokPreview && styles.storyblokWrapper
      )}
    >
      <div
        style={{ backgroundColor: bgColor }}
        className="flex justify-end items-center pr-3 rounded-t-lg h-9"
      >
        {isAuthor && logo?.filename ? (
          <img
            src={logo.filename}
            alt={projectName ?? ""}
            width={70}
            className="block object-contain"
          />
        ) : (
          <WhiteLogo className="w-5 h-5" />
        )}
      </div>
      {video_hero && video_hero.image ? (
        <ImageComponent fluid data={video_hero} />
      ) : (
        <img src={NewsImage} alt="" className="w-full h-auto" />
      )}
      <div className="p-10 pb-14 2xl:p-11 2xl:pb-16">
        <span className="text-base mb-6 block font-fieldwork-hum">
          {formatDate(video_date)}
        </span>
        <div className={styles.excerpt}>
          <LinkComponent
            url={linkUrl}
            type={linkType}
            onClickHandler={handleLinkClick}
          >
            <h2>{video_title}</h2>
          </LinkComponent>
        </div>
        <div className="flex items-center text-secondary">
          <LinkComponent
            url={linkUrl}
            type={linkType}
            className="block leading-none font-fieldwork-hum font-semibold text-xs capitalize lg:text-lg"
            onClickHandler={handleLinkClick}
            ariaLabel="Watch now"
          >
            Watch Now
            <span className={clsx("align-middle ml-4", styles.arrow)} />
          </LinkComponent>
        </div>
      </div>
    </article>
  );
};
